<template>
    <div class="nav-header">
        <div id="header-bar" class="header_header__3V385">
            <div class="header_headerView__3bj_r">
                <div class="header_leftView__1tMGe" @click="jumpRoute()">
                    <div v-if="leftIcon" class="header_leftBackIcon__1N0QU"></div>
                </div>
                <div class="header_centerView__36gtu header_headerViewKy__1TADQ">
                    <div class="header_itemText__2jqpZ">{{ title }}</div>
                </div>
                <div class="header_rightView__3_s-9"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        path: {
            type: String,
        },
        leftIcon: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        jumpRoute() {
            if (this.path) {
                this.$router.jumpRoute(this.path);
            } else {
                this.$router.jumpRoute();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/static/6736546b523c9e99f2d3.css';
</style>
