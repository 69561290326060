import axios from 'axios';
import Qs from 'qs';
import utils from '@/utils/index.js';
// import { Toast } from 'vant';
import { Toast } from 'vant';
import router from '../router/index.js';
import store from '../store';
// if (process.env.NODE_ENV === 'development') {
//     // 设置默认本地开发
//     axios.defaults.baseURL = utils.api.local_domain;
//     // axios.defaults.baseURL = '/api';
// } else if (process.env.VUE_APP_CURRENTMODE === 'test') {
//     // 测试
//     axios.defaults.baseURL = utils.api.test_domain;
// } else if (process.env.VUE_APP_CURRENTMODE === 'prod') {
//     // 默认正式
//     axios.defaults.baseURL = utils.api.prod_domain;
// }
axios.defaults.baseURL = utils.api.prod_domain;
axios.defaults.timeout = 30000;
// requestpayload  json提交
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// from data提交

Toast.setDefaultOptions({ duration: 2500 });
// 设置请求计数器
let requestNum = 0;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 请求拦截
axios.interceptors.request.use(
    (config) => {
        store.commit('showLoading');
        //设置token
        config.headers['Authorization'] = sessionStorage.token;
        // config.headers['Authorization'] = 'pjgoVpMTBF6uos4acB21y3ZD95GxcmamaDBO5BXPqiRVAIOaxr8b0JEftiZU';
        if (config.method == 'post') {
            config.data = {
                ...config.data,
                // Authorization: sessionStorage.token,
            };
        } else {
            //get
            config.params = {
                ...config.params,
                // Authorization: sessionStorage.token,
            };
        }
        // 数据
        config.data = Qs.stringify(config.data);

        requestNum++;
        if (config.isLoad) {
            Toast.loading({
                content: '加载中...',
                duration: 0,
            });
        }

        return config;
    },
    (error) => {
        store.commit('hideLoading')
        return Promise.reject(error);
    }
);

// 响应拦截
axios.interceptors.response.use(
    function (res) {
        requestNum--;
        // 清除Message
        if (requestNum == 0) {
            Toast.clear();
        }
        store.commit('hideLoading');
        //认证失败
        if (res.data.code == 401) {
            Toast(res.data.message);
            setTimeout(() => {
                router.replace({
                    path: '/entry/login',
                });
            }, 2500);
        }
        // 操作失败
        else if (res.data.code !== 200) {
            store.commit('hideLoading');
            Toast(res.data.message);
        }
        return res;
    },
    function (error) {
        Toast.clear();
        requestNum = 0;
        Toast.fail({
            content: '服务器异常~',
        });
        store.commit('hideLoading');
        return Promise.reject(error);
    }
);

//请求方法封装
export default {
    Get(url, data, isLoad) {
        return axios.get(url, { params: data }, isLoad);
    },
    Post(url, data, isLoad) {
        return axios.post(url, data, isLoad);
        // return axios({
        //     url,
        //     data,
        //     type: 'POST',
        //     isLoad,
        // });
    },
    // Put(url, data, isLoad) {
    //     return axios({
    //         url,
    //         data,
    //         type: 'PUT',
    //         isLoad,
    //     });
    // },
    // Delete(url, data, isLoad) {
    //     return axios({
    //         url,
    //         data,
    //         type: 'DELETE',
    //         isLoad,
    //     });
    // },
};

// export default axios;
