<template>
    <div id="app">
        <Loading v-show="LOADING"></Loading>
        <!-- <nav>
            <router-link to="/">Home</router-link> |
            <router-link to="/about">About</router-link>
        </nav> -->
        <!-- <component :is="layout">
            <router-view v-if="isRouterAlive"></router-view>
        </component> -->
        <router-view v-if="isRouterAlive"></router-view>
        <!--系统维护提示-->
        <div class="maintain" v-if="$store.state.systemApp.site_state == 2">
            <p>{{ $store.state.systemApp.repair_tips }}</p>
        </div>
        <!--自定义组件、内容-->
        <a id="captchaButton" ref="captchaButton" class="captchaButton">点击弹出验证窗口</a>
        <!--自定义组件、内容-->
    </div>
</template>

<script>
// import store from './store';
import bus from './bus.js';
import {mapState} from 'vuex';
import Loading from '@/views/Loading.vue';
//初始化凯格行为验证码
kg.captcha({
    // 绑定弹窗按钮
    button: '#captchaButton',
    // 验证成功事务处理
    success: function (e) {
        // 验证成功，直接提交表单
        // form1.submit();
        // console.log(store);
        // store.commit('setSystemApp', true);
        bus.$emit('send', true);
        // setTimeout(() => {
        //     kg.reload(kg.param);
        // }, 300);
    },
    // 验证失败事务处理
    failure: function (e) {
        // console.log(e);
        // store.commit('setSystemApp', false);
        bus.$emit('send', false);
    },
    // 点击刷新按钮时触发
    refresh: function (e) {
        // console.log(e);
        // store.commit('setSystemApp', false);
        bus.$emit('send', false);
    },
});
export default {
    name: 'App',
    components: {Loading},
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            //default_layout: 'default', //设置layout
            isRouterAlive: true,
        };
    },
    created() {
        if (this.$route.query.token) {
            sessionStorage.token = this.$route.query.token;
        }
        //判断、获取客服链接
        let servicerUrl = this.$store.state.servicerUrl;
        if (!servicerUrl) {
            this.$store.dispatch('getServicerUrl');
        }
        //获取余额
        if (sessionStorage.token) {
            this.$store.dispatch('getBalance');
            let userInfo = this.$store.state.userInfo;
            if (!userInfo.joinday) {
                //获取用户信息
                this.$store.dispatch('getUserInfo');
            }
        }
        //获取系统信息
        this.getSystemApp();
    },
    mounted() {
        //验证码事件监听
        bus.$on('sendVerify', () => {
            kg.reload(kg.param);
            this.$refs.captchaButton.dispatchEvent(new MouseEvent('click'));
        });
    },
    computed: {
        ...mapState(['LOADING']),
        //     layout() {
        //         return (this.$route.meta.layout || this.default_layout) + '-layout';
        //     },
    },
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true;
            });
        },
        getSystemApp() {
            this.$request.getSystemApp().then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    if (data.data.title) {
                        document.title = data.data.title;
                    }
                    this.$store.commit('setSystemApp', data.data);
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/css/app.scss';
@import '@/assets/css/static/ec78c9d14578602455d9.css';
#app {
    height: 100%;
}
.maintain {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    padding: 100px;
    color: #ffffff;
    font-size: 26px;
    background: black;
    z-index: 999999;
}
.captchaButton {
    display: none;
}
</style>
