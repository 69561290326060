<template>
    <div class="home">
        <div class="home_homeStyle__v7B-P">
            <div class="downloadHeader_download__12MCI" id="download-bar" v-if="showAppMsg">
                <div class="downloadHeader_content__11y58">
                    <div class="downloadHeader_downloadCloseBtn__cS6t4" @click="showAppMsg = false">
                        <span class="downloadHeader_close__1VC5R"></span>
                    </div>
                    <div class="image_imageContainer__2dKjX image_cover__3B5D- downloadHeader_logo__3TF0d" style="cursor: inherit">
                        <div>
                            <img
                                v-if="$store.state.systemApp.app_logo"
                                class="downloadHeader_logo__3TF0d home-img-01"
                                :src="$store.state.systemApp.app_logo"
                                decoding="async"
                                alt="logo"
                            />
                        </div>
                    </div>
                    <div class="downloadHeader_downloadIntro__1KJkt">
                        <h2>{{ $store.state.systemApp.site_keyword }}APP</h2>
                        <h6>真人娱乐，体育投注，电子游艺等尽在一手掌握</h6>
                    </div>
                    <div class="downloadHeader_btn__1xcrN" @click="goApp">立即下载</div>
                </div>
            </div>
            <!--头部-->
            <div class="banner_bannerStyle__3RRd7">
                <div class="banner_homeHeader__38NOu">
                    <div class="image_imageContainer__2dKjX image_cover__3B5D- banner_logo__3XHDQ" style="cursor: inherit">
                        <div>
                            <img class="banner_logo__3XHDQ home-img-01" :src="$store.state.systemApp.site_logo" decoding="async" />
                        </div>
                    </div>
                    <div class="banner_rg__2lme6">
                        <div class="banner_message__1cxxW" @click="$router.jumpRoute('/mine/mail')">
                            <img
                                class="banner_messageImg__K5fLI"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAb1BMVEUAAAAwMEAwMEAwNUAwNEAwNUMwM0MwNEIwMkAWnf8wNEIYm/8Wm/8wNEIXnf8wNEIXnP8wM0EwNUIwM0IwM0EwNEIwM0IwNEMwNEEwNEIwNEEwM0IwM0EXnP8WnP8wNEIwNEEwM0IwM0EXnP8wNEJ9Fx4IAAAAI3RSTlMAECAwQG9vcHB/f4CAgI+PkJCfn6Cvr7+/z8/f3+/v7+/v73qYkKQAAAFJSURBVHja7VRbcoMwDMSOC32EEBxaDLgmpNz/jLWGwR17R+7js5P9Uays5BULKv4nhCp17/Faq5/QlV7WgOWivqObNUF/yIlpdto0mmn/3QiOLzeO2bWr0m7VMsc3KpI4RhXIv1Vp+vHKVAjiW1kApKUKnKOJ+FDRpNkD6eGGI1WpIcbngn6Yg/xIDPOpoWBh4IoOMtBPRxmv0lLMXDGLpEOVK6g3BXh+cu6EETu++TPF04fHEaIHDRFLnCg6IrxD9JjiB2vomC0YoGCkeCTCGWJoCZKKs3MvGFFSR0PzwKEr1mg0KpxrpPEdxZUk8qAR5t++fBfI9JkLoF/+A3rY2sEVM/eJ0vKEBdjm908LaWG/KpBvBbNNKtS/sPunIfdwm7N8Cf+oZ7PGfPBu7gOGsO5bwZkDSLc5WAe46UBHyM7E0KUSxR1/wifsuD9Oo3SzjgAAAABJRU5ErkJggg=="
                            />
                            <i></i>
                        </div>
                    </div>
                </div>
                <!--轮播-->
                <div class="banner_bannerContent__2-sug">
                    <div
                        class="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-ios banner_swiperStyle__3nCtb"
                    >
                        <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets"></div>
                        <div class="swiper-wrapper">
                            <div class="swiper-slide banner_slideContent__35Z2A" v-for="(item, index) in bannerList" :key="index">
                                <div class="image_imageContainer__2dKjX image_cover__3B5D- banner_imgStyle__-sxwF">
                                    <div>
                                        <img :src="item.src" class="home-img-02" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--滚动公告-->
            <div>
                <div class="noticeBar_noticeBox__3xSO0">
                    <van-notice-bar background="#e8f5ff">
                        <template #left-icon>
                            <div class="notice-logo">
                                <div class="noticeBar_noticeLogo__34Syt"></div>
                            </div>
                        </template>
                        <template #default>
                            <div class="noticeBar_noticeStyle__1Nrrd">
                                <div class="noticeBar_listContent__ENaiA">
                                    <span class="noticeBar_itemStyle__z4HBi" v-for="(item, index) in noticeList" :key="index">{{
                                        item
                                    }}</span>
                                </div>
                            </div>
                        </template>
                        <template #right-icon>
                            <div class="noticeBar_noticeMore__2Umis"></div>
                        </template>
                    </van-notice-bar>
                </div>
            </div>
            <!--登录信息及一些跳转-->
            <div class="userCard_userCardStyles__1o-9T">
                <div v-if="!$store.state.userInfo.username" class="userCard_notLoginBox__3v7Uk" @click="$router.jumpRoute('/entry/login')">
                    <h1>您还未登录</h1>
                    <p>登录/注册后查看</p>
                </div>
                <div v-else class="userCard_notLoginBox__3v7Uk">
                    <div class="userCard_userGrade__2crn4">
                        <span class="userCard_username__3WiHs">{{ $store.state.userInfo.username }}</span>
                        <span
                            class="userCard_vip0__GaF8h userCard_gradeStyle__dIg6W userCard_gradeStyleNotYb__2v1x3"
                            :style="'background-image:url(' + configs.api.local_domain + $store.state.userInfo.vipname + ')'"
                        ></span>
                    </div>
                    <div class="userCard_userMoneyStyle__3uYIK">
                        <div class="userCard_moneyBox__cK0bN">
                            <div class="userCard_icon_italic__3_MFN"></div>
                            {{ $store.state.userInfo.balance }}
                        </div>
                        <div class="userCard_iconQuestion__3FKoP" @click="showTips()">
                            <div v-if="isShowTips" class="userCard_tip__2pgga">中心钱包</div>
                        </div>
                    </div>
                </div>
                <div class="userCard_walletContent__Rst4x">
                    <div class="userCard_walletItem__1ghF1" @click="$router.jumpRoute('/mine/finance/myDeposit')">
                        <div style="cursor: inherit" class="image_imageContainer__2dKjX image_cover__3B5D- userCard_iconUrl__3K291">
                            <div>
                                <img
                                    class="userCard_iconUrl__3K291 home-img-03"
                                    src="@/assets/images/static/mine_img_01.png"
                                    decoding="async"
                                />
                            </div>
                        </div>
                        <span>存款</span>
                    </div>
                    <div class="userCard_walletItem__1ghF1" @click="$router.jumpRoute('/mine/finance/transfer')">
                        <div style="cursor: inherit" class="image_imageContainer__2dKjX image_cover__3B5D- userCard_iconUrl__3K291">
                            <div>
                                <img
                                    class="userCard_iconUrl__3K291 home-img-03"
                                    src="@/assets/images/static/mine_img_02.png"
                                    decoding="async"
                                />
                            </div>
                        </div>
                        <span>转账</span>
                    </div>
                    <div class="userCard_walletItem__1ghF1" @click="$router.jumpRoute('/mine/finance/withDraw')">
                        <div style="cursor: inherit" class="image_imageContainer__2dKjX image_cover__3B5D- userCard_iconUrl__3K291">
                            <div>
                                <img
                                    class="userCard_iconUrl__3K291 home-img-03"
                                    src="@/assets/images/static/mine_img_03.png"
                                    decoding="async"
                                />
                            </div>
                        </div>
                        <span>取款</span>
                    </div>
                    <div class="userCard_walletItem__1ghF1" @click="$router.jumpRoute('/mine/vip/privilege')">
                        <div style="cursor: inherit" class="image_imageContainer__2dKjX image_cover__3B5D- userCard_iconUrl__3K291">
                            <div>
                                <img
                                    class="userCard_iconUrl__3K291 home-img-03"
                                    src="@/assets/images/static/mine_img_09.png"
                                    decoding="async"
                                />
                            </div>
                        </div>
                        <span>VIP</span>
                    </div>
                </div>
            </div>
            <!--游戏内容-->
            <div class="gameList_gameListStyles__1NZCr">
                <div class="gameList_gameSlider__2z4r0">
                    <div
                        class="gameList_iconWrap__PON9J"
                        :class="{ 'gameList_activeSlider__2RPbX gameList_ybTabScale__2R257': isActive === index }"
                        @click="isActive = index"
                        v-for="(item, index) in gameList"
                        :key="index"
                    >
                        <div class="gameList_sports__JaIki" :class="item.className" :style="'background-image: url(' + (isActive === index ? item.checkLogo : item.logo) + ');'"></div>
                        <span class="gameList_title__ROaLU">{{ item.name }}</span>
                    </div>
                </div>
                <div class="gameList_gameCard__15hNZ" v-if="gameList[isActive] && gameList[isActive].headerList">
                    <div
                        class="gameList_cardImg__1Wz0s gameList_cardImg6__1sfNL"
                        v-for="(item, index) in gameList[isActive].headerList"
                        :key="index"
                        @click="getGameUrl(item)"
                    >
                        <div class="gameList_venueImgContainer__lM0ke">
                            <div
                                class="image_imageContainer__2dKjX image_cover__3B5D-"
                                :style="'cursor: inherit;background-image: url(' + item.mobile_img + ');'"
                            >
                                <div style="height: 100%">
                                    <div
                                        v-if="index == 0"
                                        style="cursor: inherit"
                                        class="image_imageContainer__2dKjX image_cover__3B5D- gameList_official__Aq2Fn"
                                    >
                                        <div>
                                            <img
                                                class="gameList_official__Aq2Fn home-img-03"
                                                src="@/assets/images/static/is_hot.png"
                                                decoding="async"
                                            />
                                        </div>
                                    </div>
                                    <div class="gameList_titleContainer__Dbi2P">
                                        <div>
                                            <div class="gameList_titleCH__1F8mG">{{ item.name }}</div>
                                            <div class="gameList_titleEN__mBCxQ">{{ item.name_en }}</div>
                                            <div v-if="item.keywords" class="gameList_returnWater__2bsja">{{ item.keywords }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--底部-->
            <nav-footer></nav-footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeView',
    data() {
        return {
            bannerList: [],
            noticeList: [],
            gameDetail: null,
            //游戏列表concise电子/gaming电竞/joker棋牌/lottery彩票/realbet真人/sport体育
            gameList: [],
            isActive: 0,
            token: sessionStorage.token,
            isShowTips: false,
            showAppMsg: true,
        };
    },
    created() {
        //获取首页banner
        this.getBannerList();
        //获取滚动公告栏内容
        this.getHomeNotice();
        //判断、获取所有游戏
        let gameTypeList = this.$store.state.gameTypeList;
        if (gameTypeList[0].list.length <= 0) {
            gameTypeList.map((item) => {
                this.$store.dispatch('getGameTypeList', item.type);
            });
        }
        if (this.token) {
            //获取用户信息
            this.$store.dispatch('getUserInfo');
        }
    },
    mounted() {},
    updated() {
        let gameTypeList = this.$store.state.gameTypeList;
        if (gameTypeList.length > 0) {
            gameTypeList[0].className = 'gameList_sports__JaIki';
            gameTypeList[1].className = 'gameList_liveCasino__3ZIgO';
            gameTypeList[2].className = 'gameList_boardGame__2_B9R';
            gameTypeList[3].className = 'gameList_eSports__3ODCy';
            gameTypeList[4].className = 'gameList_lottery__2lfLM';
            gameTypeList[5].className = 'gameList_slotgame__2fmQ8';
            gameTypeList[0].logo = require('@/assets/images/static/game/logo1.png');
            gameTypeList[0].checkLogo = require('@/assets/images/static/game/checkLogo1.png');
            gameTypeList[1].logo = require('@/assets/images/static/game/logo2.png');
            gameTypeList[1].checkLogo = require('@/assets/images/static/game/checkLogo2.png');
            gameTypeList[2].logo = require('@/assets/images/static/game/logo3.png');
            gameTypeList[2].checkLogo = require('@/assets/images/static/game/checkLogo3.png');
            gameTypeList[3].logo = require('@/assets/images/static/game/logo4.png');
            gameTypeList[3].checkLogo = require('@/assets/images/static/game/checkLogo4.png');
            gameTypeList[4].logo = require('@/assets/images/static/game/logo5.png');
            gameTypeList[4].checkLogo = require('@/assets/images/static/game/checkLogo5.png');
            gameTypeList[5].logo = require('@/assets/images/static/game/logo6.png');
            gameTypeList[5].checkLogo = require('@/assets/images/static/game/checkLogo6.png');
            this.gameList = gameTypeList;
        }
    },
    methods: {
        bannerLeft() {
            if (this.bannerIndex > 0) {
                this.bannerIndex--;
            } else {
                this.bannerIndex = this.bannerList.length - 1;
            }
        },
        bannerRight() {
            if (this.bannerIndex < this.bannerList.length - 1) {
                this.bannerIndex++;
            } else {
                this.bannerIndex = 0;
            }
        },
        changeDownloadIndex(i) {
            this.downloadIndex = i;
            this.changeDownloadIng = true;
            setTimeout(() => {
                this.changeDownloadIng = false;
            }, 500);
        },
        //获取首页banner
        getBannerList() {
            this.$request.getBannerList({ type: 2 }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.bannerList = data.data;
                    this.$nextTick(() => {
                        let swiperSponsor = new this.$Swiper('.swiper-container', {
                            pagination: {
                                el: '.swiper-pagination',
                                clickable: true,
                            },
                            loop: true,
                        });
                    });
                }
            });
        },
        //获取滚动公告栏内容
        getHomeNotice() {
            this.$request.getHomeNotice().then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.noticeList = data.data;
                }
            });
        },
        //获取游戏链接
        getGameUrl(item) {
            //判断是否登录
            if (this.$utils.isLogin()) {
                this.$request
                    .getGameUrl({ plat_name: item.platform_name, game_type: item.game_code, game_code: item.category_id, is_mobile_url: 1 })
                    .then((res) => {
                        let data = res.data;
                        if (data.code === 200) {
                            this.$store.commit('setGameUrl', { game_code: item.game_code, url: data.data.url });
                            window.open(data.data.url, '_self');
                        }
                    });
            } else {
                this.$router.jumpRoute('/entry/login', {
                    redirect: this.$route.path,
                });
            }
        },
        asyncOpenWindow(res) {
            let newWindow = null;
            const timeOut = setTimeout(() => {
                try {
                    if (navigator.userAgent.indexOf('Chrome') > -1) {
                        newWindow = window.open('', '', 'location=no,menubar=no');
                    }
                } catch (e) {
                    console.log(e);
                }
            }, 4500);
            clearTimeout(timeOut);
            let data = res.data;
            if (data.code === 200) {
                if (newWindow) {
                    newWindow.location.href = data.data.url;
                } else {
                    window.open(data.data.url, '_blank');
                }
            }
        },
        //关闭弹窗
        closeTips() {
            this.showLoginTips = false;
        },
        //中心钱包提示显示
        showTips() {
            this.isShowTips = true;
            setTimeout(() => {
                this.isShowTips = false;
            }, 3000);
        },
        //立即下载
        goApp() {
            this.$router.push('/download');
        },
        // goApp() {
        //     let path = this.$store.state.systemApp.ios_download_url;
        //     if (path) {
        //         // window.open(path, '_blank');
        //         setTimeout(() => {
        //             let tempALink = document.createElement('a');
        //             tempALink.setAttribute('target', '_blank');
        //             tempALink.setAttribute('id', 'openWin');
        //             tempALink.setAttribute('href', path);
        //             document.body.appendChild(tempALink);
        //             tempALink.click();
        //             setTimeout(() => {
        //                 document.body.removeChild(tempALink);
        //             }, 1500);
        //         }, 100);
        //     }
        // },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/static/54191dd19e0bc77be866.css';
// @import '@/assets/css/static/5008ec54b912c9e1554a.css';
.downloadBar__downloadBar__JMTBV ul .downloadBar__close__z0XUU {
    background: url('@/assets/images/static/new/home_download_close@2x.webp') 0.3rem center/.42rem 0.42rem no-repeat;
}

.downloadBar__downloadBar__JMTBV ul .downloadBar__downloadBtn__hCEy4 {
    background-image: linear-gradient(to right, #90f0ff, #265cf6);
}

.downloadBar__downloadBar__JMTBV ul {
    background-color: #ffffff;
}

.downloadBar__downloadBar__JMTBV ul .downloadBar__downloadBtn__hCEy4 span {
    color: #ffffff;
}
.home-img-01 {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
}
.home-img-02 {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    filter: none;
    background-size: cover;
    background-image: none;
    background-position: 0% 0%;
    background-repeat: no-repeat;
}
.home-img-03 {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
}
.notice-logo {
    width: 0.36rem;
    height: 100%;
    .noticeBar_noticeLogo__34Syt {
        top: 0.2rem;
    }
}
.downloadHeader_download__12MCI .downloadHeader_content__11y58 .home-img-01 {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
}
</style>
